import { post } from '@/utils/request'
//获取列表
export function getList(data) {
    return post('psi/ingredient/listIngredient', data)
}
//添加原料
export function getAdd(data) {
    return post('psi/ingredient/addIngredient', data)
}
//修改原料
export function getEdit(data) {
    return post('psi/ingredient/editIngredient', data)
}
//删除原料
export function getDel(data) {
    return post('psi/ingredient/delIngredient', data)
}
//分类下拉
export function getOption(data) {
    return post('psi/ingredient/classifi', data)
}
//分类下拉
export function getState(data) {
    return post('psi/ingredient/ingredientSta', data)
}
