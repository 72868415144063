<!--  -->
<template>
  <div class="">
    <all-log :dialogLog="dialogLog" />
    <!-- 新增与编辑分类 -->
    <el-dialog :visible.sync="dialogFormVisible" @close="userClose()">
      <div slot="title" v-show="open">新增原料</div>
      <div slot="title" v-show="!open">编辑原料</div>
      <el-form :model="form">
        <el-form-item label="分类ID" :label-width="formLabelWidth">
          <el-select
            v-model="form.ic_id"
            style="width: 100%"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="关联任务" :label-width="formLabelWidth">
          <el-cascader
            placeholder="关联任务"
            :options="optionsTask"
            :props="optionPropsTask"
            v-model="form.task_id"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="原材料名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" placeholder="原材料名称"></el-input>
        </el-form-item>
        <el-form-item label="图片" :label-width="formLabelWidth">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传原材料图片</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setOpen()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除原料 -->
    <el-dialog :visible.sync="dialogFormVisible2" @close="userClose()">
      <div slot="title">删除原料</div>
      <div style="margin-bottom: 10px">{{ userdelinfo.name }}</div>
      <el-cascader
        placeholder="关联任务"
        :options="optionsTask"
        :props="optionPropsTask"
        v-model="delForm.task_id"
        filterable
      ></el-cascader>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="setDel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-card>
      <div class="menu-box">
        <h2>原料列表</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="4">
              <el-input placeholder="请输入内容" v-model="getInfo.info">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="addOpen(true)"
                >添加原料</el-button
              >
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>

          <!-- 表格 -->
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column
              prop="ic_name"
              label="分类名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="name"
              label="原材料名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="add_time" label="添加时间"></el-table-column>

            <el-table-column label="原料状态" width="120">
              <template slot-scope="scope">
                <el-switch
                  @change="changeState(scope.row)"
                  v-model="scope.row.state"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="2"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="原材料图片">
              <template slot-scope="scope">
                <el-image
                  style="width: 70px; height: 70px"
                  :src="scope.row.img"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="addOpen(false, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="delOpen(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="pageChange"
            :pager-count="15"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getAdd,
  getEdit,
  getDel,
  getOption,
  getState,
} from '@/api/psi/ingredient.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      delForm: {
        task_id: '',
      },

      dialogLog: {
        state: false,
      },
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      optionsTask: [],
      fileList: [],
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      loading: false,
      options: [],
      inputSearch: '',
      formLabelWidth: '100px',
      dialogFormVisible: false,
      dialogFormVisible2: false,
      tableData: [],
      getNav: [],
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        img: '',
        ic_id: '',
        task_id: [],
      },
      open: true,
    }
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async changeState(data) {
      let info = await getState({ id: data.id })
      this.judge(info)
    },

    dialogOpen() {
      this.dialogLog.state = true
    },

    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response)
      this.form.img = response.data.url
      console.log(this.fileList)
    },
    exceed() {
      this.$message({
        message: '数量超出',
        type: 'warning',
      })
    },
    //移除图片的事件
    imgRemove() {
      this.form.img = ''
      console.log(this.form)
    },
    delOpen(info) {
      this.dialogFormVisible2 = true
      this.userdelinfo = info
    },
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
        task_id: this.delForm.task_id,
      })
      this.judge(info, 0)
    },
    async addOpen(state, info) {
      this.open = state
      if (!state) {
        this.userdelinfo = info
        this.form.name = info.name
        this.form.img = info.img
        this.form.ic_id = info.ic_id
        this.fileList = [{ name: info.name, url: info.img }]
      }
      this.dialogFormVisible = true
    },
    //true为添加，false为修改
    async setOpen() {
      let info = null
      let state = this.open
      if (state) {
        info = await getAdd(this.form)
        console.log('增加')
      } else {
        let form = this.form
        form.id = this.userdelinfo.id
        info = await getEdit(form)
        console.log('编辑')
      }
      this.judge(info, true)
    },
    //change为窗口，list为state
    judge(info, change, list) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        if (list == null) {
          this.catalogue()
        }
        if (change) {
          this.dialogFormVisible = false
        } else {
          this.dialogFormVisible2 = false
        }
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      if (list == null) {
        this.catalogue()
      }
    },
    userClose() {
      this.form = {
        name: '',
        img: '',
        ic_id: '',
        task_id: [],
      }
      this.fileList = []
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    async catalogue() {
      let info = await getList(this.getInfo)
      let info2 = await getOption()
      let optionsTask = await this.$getOptionTask()
      this.optionsTask = optionsTask.data.data

      this.options = info2.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableData = info.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.menu-box {
  border-radius: 25px;
  box-shadow: -1px 3px 3px 3px #e6e6e6;
  overflow: hidden;
}
.menu-box h2 {
  text-align: center;
  font-weight: 400;
  color: black;
  background: #ddd;
  height: 50px;
  margin: 0;
  line-height: 50px;
}
.menu-box .box-bd {
  padding: 20px;
  box-sizing: border-box;
}
</style>
